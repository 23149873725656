import { useState, useEffect } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";

import { useUser } from "../../context/userContext";

interface TraitInterface {
  mood: "positive" | "negative";
  name: string;
  code: string;
  base: string;
  geo: string;
  rus: string;
  _id: string;
  adezisCode: string;
}

interface TraitMoodInterface {
  rus: string;
  geo: string;
  hide: boolean;
  code: string;
  score: number;
  _id: string;
  adezisCode: string;
}

const formattedMapTrait = () => {
  return (t: TraitInterface): TraitMoodInterface => {
    return {
      _id: t._id,
      rus: t.rus,
      geo: t.geo,
      code: t.code,
      score: 0,
      hide: false,
      adezisCode: t.adezisCode,
    };
  };
};

const Questions: React.FC<{
  finishHandler: Function;
}> = ({ finishHandler: pageFinishHandler }) => {
  const [negativeTraits, setNegativeTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [negativePercent, setNegativePercent] = useState<number>(0);
  const [positivePercent, setPositivePercent] = useState<number>(0);

  const [positiveTraits, setPositiveTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [currentCategory, setCurrentCategory] = useState<
    "negative" | "positive"
  >("negative");

  const user = useUser();

  useEffect(() => {
    axios.get("/traits").then(({ data }: { data: TraitInterface[] }) => {
      let negativeTraits = data
        .filter((t) => t.mood === "negative")
        .map(formattedMapTrait());
      let positiveTraits = data
        .filter((t) => t.mood === "positive")
        .map(formattedMapTrait());
      setNegativeTraits(negativeTraits);
      setPositiveTraits(positiveTraits);

      // setPositiveTraits(
      //   data.filter((t) => t.mood === "positive").map(formattedMapTrait())
      // );
    });
  }, []);

  const finishHandler = (
    positiveTraits: TraitMoodInterface[],
    negativeTraits: TraitMoodInterface[]
  ) => {
    let allTraits = [...positiveTraits, ...negativeTraits];

    allTraits.sort((a, b) => (a.score > b.score ? -1 : 1));

    console.log(allTraits, "allTraits");

    let result: { _id: string; value: number }[] = allTraits.map((t) => {
      return {
        _id: t._id,
        value: t.score,
      };
    });

    let message = "";
    allTraits.forEach((r) => {
      message += `${r.rus} ${r.score}\n`;
    });
    user.updateUser("test", result);
    console.log(message);
    pageFinishHandler();
  };

  const percent = Math.floor((negativePercent + positivePercent) / 2);

  const { t, i18n } = useTranslation();

  const lang = i18n.language;

  return (
    <>
      <style>
        {`
          .custom-progress::-webkit-progress-value {
            border-radius: 0; /* Убираем скругление у заполненной части */
          }
          .custom-progress::-moz-progress-bar {
            border-radius: 0; /* Убираем скругление у заполненной части для Firefox */
          }
        `}
      </style>
      <div className="card-body mb-0 pb-0">
        <p className="text-center  font-bold text-lg mt-2 font-extralight mb-3">
          {t("question.completedProgressPercent")} {percent}%
        </p>
        <progress
          className="progress progress-primary rounded-none h-6 border-solid border bg-transparent border-base-content custom-progress"
          value={percent}
          max="100"
        ></progress>
      </div>

      {negativeTraits.length && currentCategory == "negative" && (
        <MoodQuestion
          lang={lang}
          setPercent={setNegativePercent}
          question={t("question.negativeTitle")}
          initTairs={negativeTraits}
          setFinish={(traits: TraitMoodInterface[]) => {
            renderResult(traits);
            setNegativeTraits(traits);
            setCurrentCategory("positive");
          }}
        />
      )}
      {negativeTraits.length && currentCategory == "positive" && (
        <MoodQuestion
          lang={lang}
          setPercent={setPositivePercent}
          question={t("question.positiveTitle")}
          initTairs={positiveTraits}
          setFinish={(traits: TraitMoodInterface[]) => {
            setPositiveTraits(traits);
            renderResult(traits);
            finishHandler(traits, negativeTraits);
          }}
        />
      )}
    </>
  );
};

function shuffleArray(array: TraitMoodInterface[]): TraitMoodInterface[] {
  let arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const filterHideCallback = (t: TraitMoodInterface) => {
  return !t.hide;
};

function calculatePercentage(x: number, max: number): number {
  return 100 - (x / max) * 100;
}

const MoodQuestion: React.FC<{
  initTairs: TraitMoodInterface[];
  question: string;
  setFinish: Function;
  setPercent: Function;
  lang: string;
}> = ({ initTairs, question, setFinish, setPercent, lang }) => {
  const [traits, setTraits] = useState<TraitMoodInterface[]>(initTairs);
  const [answers, setAnswers] = useState<
    {
      rus: string;
      geo: string;
      _id: string;
    }[]
  >([]);

  useEffect(() => {
    nextRound();
  }, [traits]);

  const selectHandler = (_id: string) => {
    let newTraits = [...traits];
    answers.forEach((a) => {
      let index = newTraits.findIndex((t) => t._id === a._id);

      if (a._id == _id) {
        console.debug(newTraits[index].rus);
        newTraits[index] = {
          ...newTraits[index],
          hide: true,
        };
      } else {
        newTraits[index] = {
          ...newTraits[index],
          score: newTraits[index].score + 1,
        };
      }
    });
    setTraits(newTraits);
  };

  const nextRound = () => {
    let list = [...traits];

    // console.log(list, "list");
    // А можно когда вопросы , варианты подбирать максимально с разными буквами чтоб человеку было легче сливать ответы?
    // максимально разные в идеале 4 разныйх и 1 повторная

    list = list.filter(filterHideCallback);
    // если не выбраных осталось меньше 5
    if (list.length < 5) {
      let resultTraits = [...traits];
      setPercent(100);
      setFinish(resultTraits);
      return;
      //add score and fibinish
    }

    // сортируем рандомно
    list = shuffleArray(list);
    //сортируем с наимегьшими очками
    list.sort((a, b) => a.score - b.score);

    let slice = sortByPaei(list);

    // let slice = list.slice(0, 5);
    // устанавливем
    setAnswers(slice);
  };

  let percentage = calculatePercentage(
    traits.filter((t) => !t.hide).length - 4,
    traits.length - 4
  );

  const sortByPaei = (list: TraitMoodInterface[]) => {
    let arr = [...list];
    let PAEI = {
      P: arr
        .filter((p) => p.adezisCode == "P")
        .sort((a, b) => a.score - b.score),
      A: arr
        .filter((p) => p.adezisCode == "A")
        .sort((a, b) => a.score - b.score),
      E: arr
        .filter((p) => p.adezisCode == "E")
        .sort((a, b) => a.score - b.score),
      I: arr
        .filter((p) => p.adezisCode == "I")
        .sort((a, b) => a.score - b.score),
    };

    let result: TraitMoodInterface[] = [];

    for (let [key, value] of Object.entries(PAEI)) {
      if (value.length) {
        result.push(value[0]);
      }
    }

    let notInResult = arr.filter((it) => {
      let inResult = result.some((r) => r._id == it._id);
      return !inResult;
    });
    notInResult.sort((a, b) => a.score - b.score);

    let addedCount = 5 - result.length;

    if (addedCount) {
      result = [...result, ...notInResult.slice(0, addedCount)];
    }

    result.sort(() => (Math.random() > 0.5 ? 1 : -1));

    // console.log(result, "result");
    return result;
  };

  useEffect(() => {
    setPercent(percentage);
  }, [percentage]);

  return (
    <div>
      <div className="card-body pt-2">
        <p className="text-xl font-bold text-center">{question}</p>
        <div className="mt-6">
          {answers.map((a) => {
            return (
              <button
                key={a._id}
                onClick={() => selectHandler(a._id)}
                className={`btn  btn-outline btn-primary  w-full mb-3 btn-large text-lg py-3 h-auto`}
              >
                {lang == "rus" ? a.rus : a.geo}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function renderResult(list: TraitMoodInterface[]) {
  let arr = [...list];

  let message = "";
  arr.sort((a, b) => b.score - a.score);

  arr.forEach((r) => {
    message += `${r.rus} ${r.score}\n`;
  });
  console.log(message);
}

export default Questions;
