import React from "react";

import { CustomInput } from "../../components/UI/CustomInput";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import { useUser } from "../../context/userContext";
import axios from "axios";

interface FormValues {
  country: string;
  city: string;
  isAgreement: boolean;
}

interface Props {
  finishHandler: Function;
}

const Location: React.FC<Props> = ({ finishHandler }) => {
  const initialValues: FormValues = {
    country: "",
    city: "",
    isAgreement: false,
  };

  const user = useUser();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    country: Yup.string().required(t("field.requiredFieldError")),
    city: Yup.string().required(t("field.requiredFieldError")),
    isAgreement: Yup.boolean().isTrue(t("field.requiredFieldError")),
  });

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(false);

    let resultTest = Object.assign({}, user, values);
    console.log(resultTest, "resultTest");
    axios.post("/users/finish", resultTest).then(() => {});
    finishHandler();
  };

  return (
    <>
      <div className="card-body py-12 px-6">
        <p className="text-2xl text-center font-bold">{t("userLocation.title")}</p>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <CustomInput
                    name="country"
                    label={t("userLocation.inputCountryLabel")}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    errorMessage={
                      touched.country && errors.country ? errors.country : ""
                    }
                  />
                  <CustomInput
                    name="city"
                    label={t("userLocation.inputCityLabel")}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    errorMessage={
                      touched.city && errors.city ? errors.city : ""
                    }
                  />
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      name="isAgreement"
                      type="checkbox"
                      checked={values.isAgreement}
                      onChange={handleChange}
                      className="checkbox"
                    />
                    <span
                      className={`label-text ml-3 ${
                        touched.isAgreement && errors.isAgreement
                          ? "text-error"
                          : ""
                      }`}
                    >
                      {t("userLocation.agreementText")}
                    </span>
                  </label>
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className="btn btn-block btn-outline btn-primary w-full btn-large text-lg py-3 h-auto"
                  >
                    {t("button.next")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Location;
