import React from "react";
import { useUser } from "../../context/userContext";

import { useTranslation } from "react-i18next";

const domain = window.document.domain;
const isDevMode = process.env.NODE_ENV === "development";

interface CurrentStatusProps {
  finishHandler: Function;
}

const CurrentStatus: React.FC<CurrentStatusProps> = ({ finishHandler }) => {
  const user = useUser();

  const selectStatus = (status: string) => {
    user.updateUser("status", status);
    finishHandler();
  };

  const { t } = useTranslation();

  const list = [
    {
      text: t("main.currentStatus.answer-work"),
      desc: t("main.currentStatus.answer-work-desc"),
    },
    {
      text: t("main.currentStatus.answer-student"),
      desc: t("main.currentStatus.answer-student-desc"),
    },
    {
      text: t("main.currentStatus.answer-search-work"),
      desc: t("main.currentStatus.answer-search-work-desc"),
    },
  ];

  return (
    <>
      <div className="card-body py-12 px-6">
        <HeaderSection />
        <p className="text-2xl text-center font-bold">
          {t("main.currentStatus.question")}
        </p>
        <div className="mt-4">
          {list.map((a, i) => {
            return (
              <button
                key={i}
                className={`btn btn-primary btn-outline btn-block  my-2 h-auto btn-lg text-base py-3`}
                onClick={() => selectStatus("work")}
              >
                <span className="text-2xl inline leading-none font-bold">
                  {a.text}
                </span>
                <span className="text-xs text-base-content font-extralight">
                  {a.desc}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

function HeaderSection() {
  let DOMAIN = "PROD";

  const { t } = useTranslation();

  if (!isDevMode) {
    DOMAIN = domain.split(".")[0];
  }

  let logo = <p className="font-bold text-5xl text-center">{DOMAIN}</p>;

  if (DOMAIN.toLowerCase() == "thejobgate") {
    logo = (
      <p className="font-bold text-5xl text-center">
        The
        <span className="text-primary">Job</span>
        Gate
      </p>
    );
  } else if (DOMAIN.toLowerCase() == "jbhnd") {
    logo = <p className="font-bold text-primary text-5xl text-center">JBHND</p>;
  }

  return (
    <div className="mb-4">
      {logo}
      <p className="text-right mt-2 text-primary text-sm">
        {t("main.currentStatus.headline")}
      </p>
    </div>
  );
}

export default CurrentStatus;
