import { Icon } from "@iconify/react";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer  text-base-content p-10 flex justify-center">
      <nav className="flex flex-col items-center">
        <h6 className="footer-title">{t('footer.contactsHeader')}</h6>
        <div className="">
          <a href="">
            <Icon icon="ic:baseline-telegram" fontSize="32px" />
          </a>
        </div>
      </nav>
    </footer>
  );
}
