import React, { useState } from "react";
import { CustomInput } from "../../components/UI/CustomInput";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useUser } from "../../context/userContext";

import axios from "axios";

import { useTranslation } from "react-i18next";

interface FormValues {
  email: string;
  confirmEmail: string;
  promo: string;
}

const UserAuth: React.FC<{
  finishHandler: Function;
}> = ({ finishHandler }) => {
  const initialValues: FormValues = { email: "", confirmEmail: "", promo: "" };

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("field.invalidEmailError"))
      .required(t("field.requiredFieldError")),
    confirmEmail: Yup.string()
      .email(t("field.invalidEmailError"))
      .required(t("field.requiredFieldError"))
      .oneOf([Yup.ref("email")], t("field.emailDoesMatchError")),
    promo: Yup.string().email(t("field.invalidEmailError")),
  });

  const user = useUser();

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    user.updateUser("email", values.email);
    user.updateUser("promo", values.promo);
    axios
      .post("/users", {
        email: values.email,
        promo: values.promo,
      })
      .then(() => {
        finishHandler();
        actions.setSubmitting(false);
      })
      .catch((e) => {
        let data = e?.response?.data;

        if (data?.code == "promo-not-found") {
          actions.setErrors({ promo: t("userContacts.errorPromoNotFound") });
        }
        if (data?.code == "promo-signined") {
          actions.setErrors({ promo: t("userContacts.errorSignedPromoEmail") });
        }
        if (data?.code == "test-passed") {
          actions.setErrors({ promo: t("userContacts.errorTestPassed") });
        }
      });
  };

  return (
    <>
      <div className="card-body py-12 px-6">
        <p className="text-2xl text-center  font-bold">{t("userAuth.title")}</p>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <CustomInput
                    name="email"
                    label={t("userAuth.emailLabelInput")}
                    type="email"
                    placeholder="work@mail.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    errorMessage={
                      touched.email && errors.email ? errors.email : ""
                    }
                  />
                  <CustomInput
                    name="confirmEmail"
                    label={t("userAuth.confirmEmailLabelInput")}
                    type="email"
                    placeholder="work@mail.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmEmail}
                    errorMessage={
                      touched.confirmEmail && errors.confirmEmail
                        ? errors.confirmEmail
                        : ""
                    }
                  />

                  <CustomInput
                    containerClassName="mt-5"
                    name="promo"
                    label={t("userAuth.promoEmailLabelInput")}
                    type="email"
                    placeholder="promo@mail.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promo}
                    errorMessage={
                      touched.promo && errors.promo ? errors.promo : ""
                    }
                  />
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className="btn btn-large btn-outline btn-block btn-primary w-full text-lg py-3 h-auto"
                  >
                    {t("button.next")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
